import { TableDescriptions } from "domain/interfaces/table-interface"

export const ColumsTableManagerialAdapter: TableDescriptions[] = [
  { align: "right", order: 1, id: 0, dataField: "valorVenda", text: "Valor Venda", hidden: false, typeField: "monetary" },
  { align: "right", order: 2, id: 1, dataField: "valorPendencia", text: "Valor Pendencia", hidden: false, typeField: "monetary" },
  { align: "right", order: 3, id: 2, dataField: "valorCustoMedio", text: "Valor Custo Médio", hidden: false, typeField: "monetary" },
  { align: "right", order: 4, id: 3, dataField: "valorExcesso", text: "Valor Excesso", hidden: false, typeField: "monetary" },
  // { align: "right", order: 5, id: 4, dataField: "valorCorrecao", text: "valor Correcao", hidden: false, typeField: "monetary" },
  { align: "right", order: 6, id: 5, dataField: "valorRemanejamento", text: "Valor Remanejamento", hidden: false, typeField: "monetary" },
  { align: "right", order: 7, id: 6, dataField: "valorPrevisaoVenda", text: "Valor Previsao Venda", hidden: false, typeField: "monetary" },
  { align: "right", order: 8, id: 7, dataField: "valorPedido", text: "Valor Pedido", hidden: false, typeField: "monetary" },
  { align: "right", order: 9, id:8, dataField: "valorPedidoCritico", text: "Valor Pedido Crítico", hidden: false, typeField: "monetary" },
  { align: "right", order: 10, id: 9, dataField: "valorNaoPedido", text: "Valor Não Pedido", hidden: false, typeField: "monetary" },
  { align: "right", order: 11, id: 10, dataField: "valorCustoContabil", text: "Valor Custo Contábil", hidden: false, typeField: "monetary" },

  { align: "right", order: 12, id: 11, dataField: "quantidadeExcesso", text: "Excesso", hidden: false },
  { align: "right", order: 13, id: 12, dataField: "quantidadeEstoquePendencia", text: "Est e Pend = 0", hidden: false },
  // { align: "right", order: 14, id: 13, dataField: "quantidadeEstoque", text: "Quantidade Estoque", hidden: false },
  { align: "right", order: 15, id: 14, dataField: "quantidadeCritico", text: "Crítico", hidden: false },
  { align: "right", order: 16, id: 15, dataField: "quantidadeTransferenciaRemanejamento", text: "Equaliz.", hidden: false },
  { align: "right", order: 17, id: 16, dataField: "quantidadeItemEstoque", text: "Estoque", hidden: false },
  { align: "right", order: 18, id: 17, dataField: "quantidade", text: "Qtd.Pedido", hidden: false },
  
  { align: "right", order: 19, id: 18, dataField: "pontuacao", text: "Pontuação", hidden: true, fixed: true },
  { align: "right", order: 20, id: 19, dataField: "faixa", text: "Faixa", hidden: true, fixed: true },
  { align: "right", order: 21, id: 20, dataField: "filStrNmefantasia", text: "Filial Descrição", hidden: true, fixed: true },
  { align: "right", order: 22, id: 21, dataField: "filial", text: "Filial", hidden: true, fixed: true },
  { align: "right", order: 23, id: 22, dataField: "status", text: "Status", hidden: true, fixed: true },
  { align: "right", order: 24, id: 23, dataField: "curva", text: "Curva", hidden: true, fixed: true },
  { align: "right", order: 25, id: 24, dataField: "demandaAtual", text: "Faixa", hidden: true, fixed: true },
  { align: "right", order: 26, id: 25, dataField: "demandaProjetada", text: "Demanda Projetada", hidden: true, fixed: true },
  { align: "right", order: 27, id: 26, dataField: "faixaValorInicial", text: "Faixa Valor Inicial", hidden: true, fixed: true },
  { align: "right", order: 28, id: 27, dataField: "faixaValorFinal", text: "Faixa Valor Final", hidden: true, fixed: true },
  { align: "right", order: 29, id: 28, dataField: "base", text: "Base", hidden: true, fixed: true },
]
